export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover"},{"name":"format-detection","content":"telephone=no"},{"name":"apple-itunes-app","content":"app-id=6451003120"}],"link":[{"rel":"preload","as":"style","href":"https://fonts.googleapis.com/css?family=DM+Mono:400,500&display=swap"},{"rel":"stylesheet","href":"https://fonts.googleapis.com/css?family=DM+Mono:400,500&display=swap","media":"print","onload":"this.media='all'"},{"rel":"icon","type":"image/x-icon","href":"/favicon.png"}],"style":[],"script":[{"innerHTML":"(function(g,e,o,t,a,r,ge,tl,y,s){\ng.getElementsByTagName(o)[0].insertAdjacentHTML('afterbegin','<style id=\"georedirect1705421248437style\">body{opacity:0.0 !important;}</style>');\ns=function(){g.getElementById('georedirect1705421248437style').innerHTML='body{opacity:1.0 !important;}';};\ntl=function(){a=window.location.href;t=g.getElementsByTagName(o)[0];y=g.createElement(e);y.async=true;\ny.src='https://g10498469755.co/gr?id=-NoI0lT4Gm1Tj4hmplyC&refurl='+g.referrer+'&winurl='+encodeURIComponent(window.location);\nt.parentNode.insertBefore(y,t);y.onerror=function(){s()};\ngeoredirect1705421248437loaded=function(redirect){var to=0;if(redirect){to=5000};setTimeout(function(){s();},to)};}\ntl();setInterval(function(){if(a!==window.location.href){a=window.location.href;y.remove();tl();}},50);\n})(document,'script','head');"}],"noscript":[],"title":"The Flowery","htmlAttrs":{"lang":"en"},"bodyAttrs":{"class":"font-main"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"