export default {
  SET_HEADER_DATA: (state, payload) => {
    state.headerData = payload
  },

  /**
   * Sets the status of the mobile navbar. If true navbar is open, if false navbar closed
   * @param status {boolean} pass true to open navbar, false to close navbar
   */
  IS_NAVBAR_OPEN(state, status = false) {
    state.mobileNavbarOpen = status
    toggleBodyScroll(status) // Add body scroll handling
  },

  TOGGLE_NAVBAR(state) {
    state.mobileNavbarOpen = !state.mobileNavbarOpen
    toggleBodyScroll(state.mobileNavbarOpen) // Add body scroll handling
  }
}

/**
 * Function to toggle body scroll by adding/removing a 'no-scroll' class
 * @param isOpen {boolean} If true, prevents scrolling; if false, re-enables scrolling
 */
function toggleBodyScroll(isOpen) {
  // Ensure this code runs only in the browser
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    if (isOpen) {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }
  }
}
